<template>
    <b-form-group :label="labelName" :label-for="id">
        <slot name="prepend" />
        <validation-provider v-slot="{ errors }" :name="field" :rules="rules">
            <component
                :is="hasChildNode ? 'b-input-group' : 'div'"
                :class="hasChildNode ? [groupClass, errors.length > 0 ? 'is-invalid' : ''] : null"
            >
                <b-form-input
                    :id="id"
                    v-model="innerValue"
                    :type="type"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="placeholder"
                />
                <slot name="append" />
            </component>
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>
<script>
export default {
    name: 'ValidationInput',
    props: {
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: null,
            default: null,
        },
        field: {
            type: String,
            required: true,
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        groupClass: {
            type: String,
            default: 'input-group-merge',
        },
    },
    data() {
        return {
            innerValue: null,
            hasChildNode: false,
        }
    },
    computed: {
        labelName() {
            let text = this.label
            let rules = this.rules

            if (typeof rules !== 'string' && !(rules instanceof Array)) {
                rules = Object.keys(rules)
            }

            return rules.includes('required') ? text + ' *' : text
        },
    },
    watch: {
        value(value) {
            this.innerValue = value
        },
        innerValue(value) {
            this.$emit('input', value)
        },
    },
    mounted() {
        if (this.value) {
            this.innerValue = this.value
        }

        this.hasChildNode = !!this.$slots.prepend || !!this.$slots.append
    },
}
</script>
