<template>
    <vue-hcaptcha ref="hcaptcha" size="invisible" :sitekey="sitekey" @verify="onVerify"></vue-hcaptcha>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

export default {
    name: 'Captcha',
    components: { VueHcaptcha },
    data() {
        return {
            sitekey: process.env.VUE_APP_HCAPTCHA_SITEKEY,
            callback: null,
        }
    },
    methods: {
        execute(callback) {
            this.callback = callback
            this.$refs.hcaptcha.execute()
        },
        onVerify(token) {
            this.callback(token)
            this.callback = null
        },
    },
}
</script>
